.nav {
  background-color: rgba(255,255,255,0.9);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  z-index:99;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 50%;
  height: 100px;
}

.nav-right {
  margin-right: 5%;
}

/* overlay */
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #929191;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

#myNav {
  width: 100%;
}

#myNavHidden {
  width: 0%;
}

@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}
@media only screen and (max-width: 1024px) {
  .nav {
    height: 10%;
  }
}
