

.one-pic {
  overflow-y: hidden;
} 


.icon {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  z-index:99;
}