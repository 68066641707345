* {
  font-family: 'Ultra', serif;
}

.clickable, a {
  transition: 0.3s;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.clickable:hover, a:hover, .clickable:focus, a:focus {
  color: #929191;
}

.one-pic-frame {
  overflow: hidden;
}
.one-pic-frame img {
  object-fit: cover;
  margin-left: 20%;
  margin-top: 100px;
}

.field-day {
  margin-top: 130px;
}

@media only screen and (max-width: 1024px) {
  .one-pic-frame img {
    margin-top: 15%;
  }
  .field-day {
    margin-top: 15%;
  }
}
